export const DefaultRequest = {
  firstName: "",
  lastName: "",
  fiscalCode: "",
  email: "",
  contract: {
    firstName: "",
    lastName: "",
    pod: "",
    serial: "",
  },
  terms: {
    term1: "no",
    term2: "no",
  },
};

export const RequestReducer = (request, { type, payload }) => {
  switch (type) {
    case "UPDATE_PERSONAL_INFO":
      return {
        ...request,
        ...payload,
      };
    case "UPDATE_CONTRACT_INFO":
      return {
        ...request,
        contract: {
          ...request.contract,
          ...payload,
        },
      };
    case "UPDATE_ACCEPTANCE":
      return {
        ...request,
        terms: {
          ...request.terms,
          ...payload,
        },
      };

    default:
      return request;
  }
};
