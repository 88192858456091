export const DefaultRequest = {
  firstName: "",
  lastName: "",
  email: "",
  fiscalCode: "",
  pod: "",
  privacy: null,
  terms: null,
};

export const RequestReducer = (request, { type, payload }) => {
  switch (type) {
    case "UPDATE_PERSONAL_INFO":
      return {
        ...request,
        ...payload,
      };

    default:
      return request;
  }
};
