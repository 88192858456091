import React, { useState, useReducer } from "react";
// import { useForm } from 'react-hook-form';
import { RequestReducer, DefaultRequest } from "./reducers/RequestReducer";
import {
  createAristonAdmissibilityRequest,
  createAdmissibilityRequest,
} from "../graphql/mutations";

import logo from "../assets/LogoAriston.png";

import { API } from "aws-amplify";

import "./admissibility.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import { Success, Failure } from "./components";
import ReviewAdmissibility from "./review-admissibility";
import RequesterData from "./requester-data";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mac-italia.com/">
        MAC SRL
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    fontWeight: 700,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  buttonPrincipal: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#B12431",
    color: "white",
    height: "50px",
    width: "200px",
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: "#B12431",
    borderColor: "#B12431",
    border: "1px solid",
    height: "50px",
    width: "200px",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
  },
}));

const steps = ["Dati richiedente", "Accettazione termini"];

function getStepContent(step, request, setRequest) {
  switch (step) {
    case 0:
      return <RequesterData request={request} setRequest={setRequest} />;
    case 1:
      return <ReviewAdmissibility request={request} setRequest={setRequest} />;
    default:
      throw new Error("Unknown step");
  }
}

function validStepContent(step, request, setRequest) {
  switch (step) {
    case 0:
      return (
        request.firstName != null &&
        request.firstName !== "" &&
        request.lastName != null &&
        request.lastName !== "" &&
        request.email != null &&
        request.email !== "" &&
        validatePOD(request.pod) &&
        validateFiscalCode(request.fiscalCode) &&
        validateEmail(request.email)
      );
    case 1:
      return request.privacy === "yes" && request.terms === "yes";
    default:
      return false;
  }
}

function validateFiscalCode(fiscalCode) {
  const re =
    /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
  re.test(fiscalCode)
    ? console.log("Fiscal Code is valid")
    : console.log("Fiscal Code is not valid");
  return re.test(fiscalCode);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Regex per validare il POD

function validatePOD(pod) {
  const re = /^IT\d{3}E\d{8,10}$/i;
  // const re = /^IT[0-9]{3}E\d{8,9}$/i;
  return re.test(pod);
}

function AdmissibilityForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(null);
  const [request, setRequest] = useReducer(RequestReducer, DefaultRequest);

  const onSubmit = async () => {
    // try {
    // Creo AristonAdmissibility Request
    // id: ID!
    // pod: ID!
    // parentID: ID
    // fiscalCode: String
    // email: String
    // firstName: String
    // lastName: String
    // message: String
    // status: AdmissibilityStatus
    // closedAt: AWSDateTime
    // createdAt: AWSDateTime
    // updatedAt: AWSDateTime
    // group: String

    const inputAriston = {
      pod: request.pod.toUpperCase(),
      parentID: request.parentID,
      fiscalCode: request.fiscalCode.toUpperCase(),
      email: request.email,
      firstName: request.firstName,
      lastName: request.lastName,
      status: "PENDING",
      group: "Ariston",
    };
    console.log("INPUT ARISTON", inputAriston);
    const _aristonReq = await API.graphql({
      query: createAristonAdmissibilityRequest,
      variables: {
        input: inputAriston,
      },
      authMode: "AWS_IAM",
    });
    console.log(_aristonReq);
    // Create Admissibility Request
    // id: ID!
    // pod: ID!
    // parentType: String
    // parentID: ID
    // fiscalCode: String
    // message: String
    // status: AdmissibilityStatus
    // closedAt: AWSDateTime
    // createdAt: AWSDateTime
    // updatedAt: AWSDateTime
    // group: String

    const portalInput = {
      pod: request.pod.toUpperCase(),
      parentType: "AristonAdmissibilityRequest",
      parentID: _aristonReq.data.createAristonAdmissibilityRequest.id,
      fiscalCode: request.fiscalCode.toUpperCase(),
      status: "PENDING",
      group: "Ariston",
    };
    console.log("PORTAL INPUT", portalInput);
    const portalResponse = await API.graphql({
      query: createAdmissibilityRequest,
      variables: {
        input: portalInput,
      },
      authMode: "AWS_IAM",
    });
    console.log(portalResponse);
    setSuccess(true);
    // } catch (e) {
    //   console.error(e);
    //   setSuccess(false);
    // }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onSubmit();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <form>
            <div className="logo">
              <img src={logo} alt="Ariston" />
            </div>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              className={classes.title}
            >
              AMMISSIBILITÀ SERVIZIO CHAIN2
            </Typography>
            <Stepper alternativeLabel activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {success == null ? (
                    <div className={classes.progress}>
                      <CircularProgress />
                    </div>
                  ) : success === false ? (
                    <Failure />
                  ) : (
                    <Success />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep, request, setRequest)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        className={classes.buttonBack}
                      >
                        Indietro
                      </Button>
                    )}
                    <Button
                      disabled={
                        !validStepContent(activeStep, request, setRequest)
                      }
                      variant="contained"
                      onClick={handleNext}
                      className={classes.buttonPrincipal}
                    >
                      {activeStep === steps.length - 1
                        ? "Invia richiesta"
                        : "Continua"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </form>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

export default AdmissibilityForm;
