import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { CardActionArea, CardContent, CardMedia } from "@material-ui/core";

export default function RequesterData({ request, setRequest }) {
  const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 200,
      objectFit: "cover",
    },
    title: {
      marginBottom: 16,
      fontSize: "16px",
      fontWeight: "700",
      lineHeight: "19.49px",
    },
  });

  const classes = useStyles();

  // handle onchange
  const handleChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
  };

  function validateFiscalCode(fiscalCode) {
    const re =
      /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/;
    return re.test(fiscalCode.toUpperCase());
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validatePOD(pod) {
    const re = /^IT\d{3}E\d{8,10}$/i;
    // const re = /^IT[0-9]{3}E\d{8,9}$/i;
    return re.test(pod);
  }

  return (
    <React.Fragment>
      <Typography gutterBottom className={classes.title}>
        DATI INTESTATARIO FORNITURA
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            variant="outlined"
            value={request.firstName}
            label="Nome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            value={request.lastName}
            variant="outlined"
            label="Cognome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="fiscalCode"
            name="fiscalCode"
            variant="outlined"
            error={
              validateFiscalCode(request.fiscalCode) === false &&
              request.fiscalCode !== ""
            }
            helperText={
              validateFiscalCode(request.fiscalCode.toUpperCase()) ||
              request.fiscalCode === ""
                ? ""
                : "Codice fiscale non valido"
            }
            value={request.fiscalCode}
            label="Codice Fiscale"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="fiscal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            type="email"
            id="email"
            name="email"
            error={
              validateEmail(request.email) === false && request.email !== ""
            }
            helperText={
              validateEmail(request.email) || request.email === ""
                ? ""
                : "Email non valida"
            }
            variant="outlined"
            value={request.email}
            label="E-mail"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="email"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            type="pod"
            id="pod"
            name="pod"
            variant="outlined"
            error={validatePOD(request.pod) === false && request.pod !== ""}
            helperText={
              validatePOD(request.pod) || request.pod === ""
                ? ""
                : "Codice POD non valido"
            }
            value={request.pod}
            label="Codice POD"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="pod"
          />
        </Grid>
        <div className="logo">
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image="./assets/pod.png"
              title="Bolletta"
            />
            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                Individuabile nella sezione “dati fornitura” del frontespizio
                della bolletta
              </Typography>
            </CardContent>
          </CardActionArea>
        </div>
      </Grid>
    </React.Fragment>
  );
}
