import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import Privacy from "./components/privacy";

// const preventDefault = (event) => event.preventDefault();

// const theme = useTheme();
const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginBottom: 16,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19.49px",
  },
  accordion: {
    padding: "0px",
    marginLeft: "8px",
    // Aligm text center
    textAlign: "center",
  },
  top: {
    paddingTop: "0px",
    paddingBottom: "20px",
  },
  // radio: {},
}));

export default function ReviewAdmissibility({ request, setRequest }) {
  const classes = useStyles();
  // handle onchange
  const handleChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
  };

  return (
    <React.Fragment>
      <div className={classes.top}>
        <Accordion>
          <AccordionSummary
            className={classes.accordion}
            id="panel-header"
            aria-controls="panel-content"
            expandIcon={<ExpandMoreIcon />}
            // expandIcon={<ExpandMoreIcon />}
          >
            INFORMATIVA RELATIVA AL TRATTAMENTO DEI DATI PERSONALI DEI CLIENTI
            AI SENSI DELL’ART 13 DEL REGOLAMENTO (UE) 2016/679 (“GDPR”)
          </AccordionSummary>
          <AccordionDetails>
            <Privacy />
          </AccordionDetails>
        </Accordion>
      </div>
      {/* <Accordion>
        <AccordionSummary
          className={classes.accordion}
          id="panel-header"
          aria-controls="panel-content"
        >
          INFORMATIVA RELATIVA AL TRATTAMENTO DEI DATI PERSONALI NELL’AMBITO DEI
          CONTRATTI CON I CLIENTI AI SENSI DEL REGOLAMENTO (UE) 2016/679
          (“GDPR”)
        </AccordionSummary>
      </Accordion>
      <Accordion>
        <AccordionSummary
          className={classes.accordion}
          id="panel-header"
          aria-controls="panel-content"
        >
          INFORMATIVA RELATIVA AL TRATTAMENTO DEI DATI PERSONALI DEI FORNITORI
          AI SENSI DELL’ART 13 REGOLAMENTO (UE) 2016/679 (“GDPR”)
        </AccordionSummary>
      </Accordion> */}

      <Typography gutterBottom className={classes.title}>
        PRIVACY POLICY
      </Typography>
      <Typography variant="body2" gutterBottom align="center">
        Presa visione dell'Informativa Privacy sopra riportata, consapevole che
        il mio consenso è puramente facoltativo, oltre che revocabile in
        qualsiasi momento.
      </Typography>
      <ol type="a">
        <li>
          esprimo il consenso al trattamento dei miei dati da parte di Ariston
          S.p.A per le finalità di marketing diretto sopra indicate: invio di
          comunicazioni commerciali/promozionali, tramite modalità automatizzate
          di contatto (come sms, mms, e-mail, social networks, app di
          messaggistica istantanea) e tradizionali (come telefonate con
          operatore e posta tradizionale) sui prodotti e servizi di Ariston
          S.p.A, segnalazione di eventi aziendali, rilevazione del grado di
          soddisfazione della clientela, nonché realizzazione di indagini di
          mercato ed analisi statistiche
        </li>
      </ol>
      <RadioGroup
        row
        aria-label="gender"
        name="privacy"
        className={classes.radio}
        value={request.privacy || "unknown"}
        onChange={(e) => handleChange(e)}
      >
        <Grid item xs={6} align="center">
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Acconsento"
          />
        </Grid>
        <Grid item xs={6} align="center">
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="Non acconsento"
          />
        </Grid>
      </RadioGroup>
      <ol type="a" start="2">
        <li>
          esprimo il consenso al trattamento automatizzato dei miei dati
          personali, ivi inclusa la profilazione, effettuato per analizzare le
          mie preferenze, abitudini, interessi e quindi al fine di ricevere
          comunicazioni commerciali personalizzate/azioni promozionali
          mirate/servizi adeguati alle mie esigenze/preferenze/suggerimenti su
          altri prodotti per me potenzialmente interessanti sulla base dei
          prodotti che ho precedentemente acquistato
        </li>
      </ol>
      <RadioGroup
        row
        aria-label="gender"
        name="terms"
        value={request.terms || "unknown"}
        onChange={(e) => handleChange(e)}
      >
        <Grid item xs={6} align="center">
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label="Acconsento"
          />
        </Grid>
        <Grid item xs={6} align="center">
          <FormControlLabel
            value="no"
            control={<Radio />}
            label="Non Acconsento"
          />
        </Grid>
      </RadioGroup>
    </React.Fragment>
  );
}
