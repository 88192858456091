import React from 'react';
import Typography from '@material-ui/core/Typography';

const Failure = () => {
  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        Attenzione!
      </Typography>
      <Typography variant='subtitle1'>
        Si è verificato un problema con la registrazione della tua richiesta. Ricarica la pagina e ritenta.
      </Typography>
      <Typography variant='subtitle1'>
        In caso il problema persista ti invitiamo a contattare l’assistenza tecnica al
        numero 0732 633528*.
      </Typography>
      <Typography variant='subtitle2'>
        * I costi della chiamata da rete fissa e mobile dipendono dalle
        condizioni contrattuali con il proprio gestore senza oneri aggiuntivi.
      </Typography>
    </React.Fragment>
  );
};

export default Failure;
