import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
    objectFit: "cover",
  },
  title: {
    marginBottom: 16,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19.49px",
  },
});

export default function AddressForm({ request, setRequest }) {
  const classes = useStyles();

  // handle onchange
  const handleChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom className={classes.title}>
        DATI RICHIEDENTE
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            id="firstName"
            name="firstName"
            value={request.firstName}
            label="Nome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            id="lastName"
            name="lastName"
            value={request.lastName}
            label="Cognome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            variant="outlined"
            type="email"
            id="email"
            name="email"
            value={request.email}
            label="E-mail"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="email"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body2" gutterBottom>
            Con l'invio di questo modulo chiedo l’abilitazione del servizio di
            chain2 dei distributori al solo fine di far comunicare lo scaldacqua
            di Ariston con il contatore sotto identificato per usufruire del
            servizio di Chain2.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
