import React from 'react';
import Typography from '@material-ui/core/Typography';

const Success = () => {
  return (
    <React.Fragment>
      <Typography variant='h5' gutterBottom>
        Grazie!
      </Typography>
      <Typography variant='subtitle1'>
        La richiesta di attivazione del servizio di chain2, che consente allo
        scaldacqua Powerflex di Ariston di comunicare con il contatore, è stata
        presa in carico.
      </Typography>
      <Typography variant='subtitle1'>
        La conferma di avvenuta attivazione del servizio avverrà entro 7 giorni
        lavorativi.
      </Typography>
      <Typography variant='subtitle1'>
        Nel caso di problemi è possibile contattare l’assistenza tecnica al
        numero 0732 633528*.
      </Typography>
      <Typography variant='subtitle2'>
        * I costi della chiamata da rete fissa e mobile dipendono dalle
        condizioni contrattuali con il proprio gestore senza oneri aggiuntivi.
      </Typography>
    </React.Fragment>
  );
};

export default Success;
