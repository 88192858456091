/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updateAdmissibilityStatus = /* GraphQL */ `
  mutation UpdateAdmissibilityStatus($input: UpdateAdmissibilityStatusInput!) {
    updateAdmissibilityStatus(input: $input) {
      id
      pod
      parentType
      parentID
      fiscalCode
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateAssociationStatus = /* GraphQL */ `
  mutation UpdateAssociationStatus($input: UpdateAssociationStatusInput!) {
    updateAssociationStatus(input: $input) {
      id
      pod
      serial
      m2
      m2B
      m2C
      podType
      m2Type
      m2BType
      m2CType
      m2Status
      m2BStatus
      m2CStatus
      userType
      firstName
      lastName
      email
      fiscalCode
      contractSigned
      parentType
      parentID
      message
      status
      closedAt
      createdAt
      updatedAt
      owner
      group
      __typename
    }
  }
`;
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input)
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input)
  }
`;
export const createAPIKey = /* GraphQL */ `
  mutation CreateAPIKey(
    $input: CreateAPIKeyInput!
    $condition: ModelAPIKeyConditionInput
  ) {
    createAPIKey(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAPIKey = /* GraphQL */ `
  mutation UpdateAPIKey(
    $input: UpdateAPIKeyInput!
    $condition: ModelAPIKeyConditionInput
  ) {
    updateAPIKey(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteAPIKey = /* GraphQL */ `
  mutation DeleteAPIKey(
    $input: DeleteAPIKeyInput!
    $condition: ModelAPIKeyConditionInput
  ) {
    deleteAPIKey(input: $input, condition: $condition) {
      id
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createAdmissibilityRequest = /* GraphQL */ `
  mutation CreateAdmissibilityRequest(
    $input: CreateAdmissibilityRequestInput!
    $condition: ModelAdmissibilityRequestConditionInput
  ) {
    createAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentType
      parentID
      fiscalCode
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateAdmissibilityRequest = /* GraphQL */ `
  mutation UpdateAdmissibilityRequest(
    $input: UpdateAdmissibilityRequestInput!
    $condition: ModelAdmissibilityRequestConditionInput
  ) {
    updateAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentType
      parentID
      fiscalCode
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteAdmissibilityRequest = /* GraphQL */ `
  mutation DeleteAdmissibilityRequest(
    $input: DeleteAdmissibilityRequestInput!
    $condition: ModelAdmissibilityRequestConditionInput
  ) {
    deleteAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentType
      parentID
      fiscalCode
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createAssociationRequest = /* GraphQL */ `
  mutation CreateAssociationRequest(
    $input: CreateAssociationRequestInput!
    $condition: ModelAssociationRequestConditionInput
  ) {
    createAssociationRequest(input: $input, condition: $condition) {
      id
      pod
      serial
      m2
      m2B
      m2C
      podType
      m2Type
      m2BType
      m2CType
      m2Status
      m2BStatus
      m2CStatus
      userType
      firstName
      lastName
      email
      fiscalCode
      contractSigned
      parentType
      parentID
      message
      status
      closedAt
      createdAt
      updatedAt
      owner
      group
      __typename
    }
  }
`;
export const updateAssociationRequest = /* GraphQL */ `
  mutation UpdateAssociationRequest(
    $input: UpdateAssociationRequestInput!
    $condition: ModelAssociationRequestConditionInput
  ) {
    updateAssociationRequest(input: $input, condition: $condition) {
      id
      pod
      serial
      m2
      m2B
      m2C
      podType
      m2Type
      m2BType
      m2CType
      m2Status
      m2BStatus
      m2CStatus
      userType
      firstName
      lastName
      email
      fiscalCode
      contractSigned
      parentType
      parentID
      message
      status
      closedAt
      createdAt
      updatedAt
      owner
      group
      __typename
    }
  }
`;
export const deleteAssociationRequest = /* GraphQL */ `
  mutation DeleteAssociationRequest(
    $input: DeleteAssociationRequestInput!
    $condition: ModelAssociationRequestConditionInput
  ) {
    deleteAssociationRequest(input: $input, condition: $condition) {
      id
      pod
      serial
      m2
      m2B
      m2C
      podType
      m2Type
      m2BType
      m2CType
      m2Status
      m2BStatus
      m2CStatus
      userType
      firstName
      lastName
      email
      fiscalCode
      contractSigned
      parentType
      parentID
      message
      status
      closedAt
      createdAt
      updatedAt
      owner
      group
      __typename
    }
  }
`;
export const createTicket = /* GraphQL */ `
  mutation CreateTicket(
    $input: CreateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    createTicket(input: $input, condition: $condition) {
      id
      type
      description
      status
      serial
      email
      note
      message
      m1
      m2
      m2B
      m2C
      podWithProblem
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTicket = /* GraphQL */ `
  mutation UpdateTicket(
    $input: UpdateTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    updateTicket(input: $input, condition: $condition) {
      id
      type
      description
      status
      serial
      email
      note
      message
      m1
      m2
      m2B
      m2C
      podWithProblem
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTicket = /* GraphQL */ `
  mutation DeleteTicket(
    $input: DeleteTicketInput!
    $condition: ModelTicketConditionInput
  ) {
    deleteTicket(input: $input, condition: $condition) {
      id
      type
      description
      status
      serial
      email
      note
      message
      m1
      m2
      m2B
      m2C
      podWithProblem
      group
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createJuice = /* GraphQL */ `
  mutation CreateJuice(
    $input: CreateJuiceInput!
    $condition: ModelJuiceConditionInput
  ) {
    createJuice(input: $input, condition: $condition) {
      id
      serialNumberEnel
      skuCode
      eanCode
      macCode
      owner
      progId
      releaseMeter
      du
      key
      etichetta
      manufacturer
      productionDate
      deliveryDate
      productionWeek
      deliveryWeek
      deliveryAddress
      po
      ddt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateJuice = /* GraphQL */ `
  mutation UpdateJuice(
    $input: UpdateJuiceInput!
    $condition: ModelJuiceConditionInput
  ) {
    updateJuice(input: $input, condition: $condition) {
      id
      serialNumberEnel
      skuCode
      eanCode
      macCode
      owner
      progId
      releaseMeter
      du
      key
      etichetta
      manufacturer
      productionDate
      deliveryDate
      productionWeek
      deliveryWeek
      deliveryAddress
      po
      ddt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteJuice = /* GraphQL */ `
  mutation DeleteJuice(
    $input: DeleteJuiceInput!
    $condition: ModelJuiceConditionInput
  ) {
    deleteJuice(input: $input, condition: $condition) {
      id
      serialNumberEnel
      skuCode
      eanCode
      macCode
      owner
      progId
      releaseMeter
      du
      key
      etichetta
      manufacturer
      productionDate
      deliveryDate
      productionWeek
      deliveryWeek
      deliveryAddress
      po
      ddt
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPowerflexRequest = /* GraphQL */ `
  mutation CreatePowerflexRequest(
    $input: CreatePowerflexRequestInput!
    $condition: ModelPowerflexRequestConditionInput
  ) {
    createPowerflexRequest(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      email
      serial
      m1
      m2
      ownerFirstName
      ownerLastName
      fiscalCode
      message
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const updatePowerflexRequest = /* GraphQL */ `
  mutation UpdatePowerflexRequest(
    $input: UpdatePowerflexRequestInput!
    $condition: ModelPowerflexRequestConditionInput
  ) {
    updatePowerflexRequest(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      email
      serial
      m1
      m2
      ownerFirstName
      ownerLastName
      message
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const deletePowerflexRequest = /* GraphQL */ `
  mutation DeletePowerflexRequest(
    $input: DeletePowerflexRequestInput!
    $condition: ModelPowerflexRequestConditionInput
  ) {
    deletePowerflexRequest(input: $input, condition: $condition) {
      id
      status
      firstName
      lastName
      email
      serial
      m1
      m2
      ownerFirstName
      ownerLastName
      message
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const createAristonAdmissibilityRequest = /* GraphQL */ `
  mutation CreateAristonAdmissibilityRequest(
    $input: CreateAristonAdmissibilityRequestInput!
    $condition: ModelAristonAdmissibilityRequestConditionInput
  ) {
    createAristonAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentID
      fiscalCode
      email
      firstName
      lastName
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateAristonAdmissibilityRequest = /* GraphQL */ `
  mutation UpdateAristonAdmissibilityRequest(
    $input: UpdateAristonAdmissibilityRequestInput!
    $condition: ModelAristonAdmissibilityRequestConditionInput
  ) {
    updateAristonAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentID
      fiscalCode
      email
      firstName
      lastName
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteAristonAdmissibilityRequest = /* GraphQL */ `
  mutation DeleteAristonAdmissibilityRequest(
    $input: DeleteAristonAdmissibilityRequestInput!
    $condition: ModelAristonAdmissibilityRequestConditionInput
  ) {
    deleteAristonAdmissibilityRequest(input: $input, condition: $condition) {
      id
      pod
      parentID
      fiscalCode
      email
      firstName
      lastName
      message
      status
      closedAt
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createEngine = /* GraphQL */ `
  mutation CreateEngine(
    $input: CreateEngineInput!
    $condition: ModelEngineConditionInput
  ) {
    createEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      duName
      hwVersion
      swVersion
      fwVersion
      mac
      initKey
      key
      systemTitle
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const updateEngine = /* GraphQL */ `
  mutation UpdateEngine(
    $input: UpdateEngineInput!
    $condition: ModelEngineConditionInput
  ) {
    updateEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      duName
      hwVersion
      swVersion
      fwVersion
      mac
      initKey
      key
      systemTitle
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEngine = /* GraphQL */ `
  mutation DeleteEngine(
    $input: DeleteEngineInput!
    $condition: ModelEngineConditionInput
  ) {
    deleteEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      duName
      hwVersion
      swVersion
      fwVersion
      mac
      initKey
      key
      systemTitle
      group
      createdAt
      processedAt
      updatedAt
      __typename
    }
  }
`;
export const createChain2Gate = /* GraphQL */ `
  mutation CreateChain2Gate(
    $input: CreateChain2GateInput!
    $condition: ModelChain2GateConditionInput
  ) {
    createChain2Gate(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateChain2Gate = /* GraphQL */ `
  mutation UpdateChain2Gate(
    $input: UpdateChain2GateInput!
    $condition: ModelChain2GateConditionInput
  ) {
    updateChain2Gate(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteChain2Gate = /* GraphQL */ `
  mutation DeleteChain2Gate(
    $input: DeleteChain2GateInput!
    $condition: ModelChain2GateConditionInput
  ) {
    deleteChain2Gate(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createC2GMeterMonofase = /* GraphQL */ `
  mutation CreateC2GMeterMonofase(
    $input: CreateC2GMeterMonofaseInput!
    $condition: ModelC2GMeterMonofaseConditionInput
  ) {
    createC2GMeterMonofase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateC2GMeterMonofase = /* GraphQL */ `
  mutation UpdateC2GMeterMonofase(
    $input: UpdateC2GMeterMonofaseInput!
    $condition: ModelC2GMeterMonofaseConditionInput
  ) {
    updateC2GMeterMonofase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteC2GMeterMonofase = /* GraphQL */ `
  mutation DeleteC2GMeterMonofase(
    $input: DeleteC2GMeterMonofaseInput!
    $condition: ModelC2GMeterMonofaseConditionInput
  ) {
    deleteC2GMeterMonofase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createC2GMeterTrifase = /* GraphQL */ `
  mutation CreateC2GMeterTrifase(
    $input: CreateC2GMeterTrifaseInput!
    $condition: ModelC2GMeterTrifaseConditionInput
  ) {
    createC2GMeterTrifase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateC2GMeterTrifase = /* GraphQL */ `
  mutation UpdateC2GMeterTrifase(
    $input: UpdateC2GMeterTrifaseInput!
    $condition: ModelC2GMeterTrifaseConditionInput
  ) {
    updateC2GMeterTrifase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteC2GMeterTrifase = /* GraphQL */ `
  mutation DeleteC2GMeterTrifase(
    $input: DeleteC2GMeterTrifaseInput!
    $condition: ModelC2GMeterTrifaseConditionInput
  ) {
    deleteC2GMeterTrifase(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createC2GDin = /* GraphQL */ `
  mutation CreateC2GDin(
    $input: CreateC2GDinInput!
    $condition: ModelC2GDinConditionInput
  ) {
    createC2GDin(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateC2GDin = /* GraphQL */ `
  mutation UpdateC2GDin(
    $input: UpdateC2GDinInput!
    $condition: ModelC2GDinConditionInput
  ) {
    updateC2GDin(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteC2GDin = /* GraphQL */ `
  mutation DeleteC2GDin(
    $input: DeleteC2GDinInput!
    $condition: ModelC2GDinConditionInput
  ) {
    deleteC2GDin(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createC2GDinModbus = /* GraphQL */ `
  mutation CreateC2GDinModbus(
    $input: CreateC2GDinModbusInput!
    $condition: ModelC2GDinModbusConditionInput
  ) {
    createC2GDinModbus(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateC2GDinModbus = /* GraphQL */ `
  mutation UpdateC2GDinModbus(
    $input: UpdateC2GDinModbusInput!
    $condition: ModelC2GDinModbusConditionInput
  ) {
    updateC2GDinModbus(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteC2GDinModbus = /* GraphQL */ `
  mutation DeleteC2GDinModbus(
    $input: DeleteC2GDinModbusInput!
    $condition: ModelC2GDinModbusConditionInput
  ) {
    deleteC2GDinModbus(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const createC2GEngine = /* GraphQL */ `
  mutation CreateC2GEngine(
    $input: CreateC2GEngineInput!
    $condition: ModelC2GEngineConditionInput
  ) {
    createC2GEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const updateC2GEngine = /* GraphQL */ `
  mutation UpdateC2GEngine(
    $input: UpdateC2GEngineInput!
    $condition: ModelC2GEngineConditionInput
  ) {
    updateC2GEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
export const deleteC2GEngine = /* GraphQL */ `
  mutation DeleteC2GEngine(
    $input: DeleteC2GEngineInput!
    $condition: ModelC2GEngineConditionInput
  ) {
    deleteC2GEngine(input: $input, condition: $condition) {
      id
      m1
      m2
      m2B
      m2C
      m1Type
      m2Type
      m2BType
      m2CType
      loginKey
      duName
      hwVersion
      swVersion
      mac
      k1
      k2
      systemTitle
      createdAt
      updatedAt
      group
      __typename
    }
  }
`;
