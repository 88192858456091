import React, { useState, useReducer } from "react";
// import { useForm } from 'react-hook-form';
import { RequestReducer, DefaultRequest } from "./reducers/RequestReducer";
import {
  createPowerflexRequest,
  createAssociationRequest,
} from "../graphql/mutations";

import logo from "../assets/LogoAriston.png";

import { API } from "aws-amplify";

import "./association.css";
import CssBaseline from "@material-ui/core/CssBaseline";
import { makeStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";

import AddressForm from "./AddressForm";
import PodForm from "./PodForm";
import Review from "./Review";
import { Success, Failure } from "./components";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://www.mac-italia.com/">
        MAC SRL
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    fontWeight: 700,
  },
  layout: {
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(2) * 2)]: {
      width: 800,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  buttonPrincipal: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    backgroundColor: "#B12431",
    color: "white",
    height: "50px",
    width: "200px",
  },
  buttonBack: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
    color: "#B12431",
    borderColor: "#B12431",
    border: "1px solid",
    height: "50px",
    width: "200px",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
  },
}));

const steps = ["Dati richiedente", "Dati contrattuali", "Accettazione termini"];

function getStepContent(step, request, setRequest) {
  switch (step) {
    case 0:
      return <AddressForm request={request} setRequest={setRequest} />;
    case 1:
      return <PodForm request={request} setRequest={setRequest} />;
    case 2:
      return <Review request={request} setRequest={setRequest} />;
    default:
      throw new Error("Unknown step");
  }
}

function validStepContent(step, request, setRequest) {
  switch (step) {
    case 0:
      return (
        request.firstName != null &&
        request.firstName !== "" &&
        request.lastName != null &&
        request.lastName !== "" &&
        request.email != null &&
        request.email !== "" &&
        validateEmail(request.email)
      );
    case 1:
      return (
        request.ownerFirstName != null &&
        request.ownerFirstName !== "" &&
        request.ownerLastName != null &&
        request.ownerLastName !== "" &&
        request.pod != null &&
        request.pod !== "" &&
        validatePOD(request.pod) &&
        request.serial != null &&
        request.serial !== "" &&
        validateSerial(request.serial)
      );
    case 2:
      return request.term1 === "yes" && request.term2 === "yes";
    default:
      return false;
  }
}

function validateSerial(serial) {
  const re = /^\d{1}[abcdefABCDEF]\w{6}$/;
  return re.test(serial);
}

function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Regex per validare il POD

function validatePOD(pod) {
  const re = /^IT\d{3}E\d{8,10}$/i;
  // const re = /^IT[0-9]{3}E\d{8,9}$/i;
  return re.test(pod);
}

function AssociationForm() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [success, setSuccess] = useState(null);
  const [request, setRequest] = useReducer(RequestReducer, DefaultRequest);

  const createAssociation = async (data) => {
    try {
      const input = {
        firstName: data.firstName,
        lastName: data.lastName,
        fiscalCode: data.fiscalCode,
        email: data.email,
        serial: data.serial,
        pod: data.m1,
        status: "PENDING",
        contractSigned: true,
        parentType: "PowerflexRequest",
        parentID: data.parentID,
        group: "Ariston",
      };
      await API.graphql({
        query: createAssociationRequest,
        variables: {
          input,
        },
        authMode: "AWS_IAM",
      });
    } catch (e) {
      console.error(e);
    }
  };

  const onSubmit = async () => {
    try {
      const input = {
        firstName: request.firstName,
        lastName: request.lastName,
        email: request.email,
        fiscalCode: request.fiscalCode,
        serial: request.serial.toUpperCase(),
        m1: request.pod.substr(0, 16).toUpperCase(),
        status: "PENDING",
        ownerFirstName: request.ownerFirstName,
        ownerLastName: request.ownerLastName,
      };
      const _powerflexReq = await API.graphql({
        query: createPowerflexRequest,
        variables: {
          input,
        },
        authMode: "AWS_IAM",
      });
      const reqID = _powerflexReq.data.createPowerflexRequest.id;
      createAssociation({ ...input, parentID: reqID });
      setSuccess(true);
    } catch (e) {
      console.error(e);
      setSuccess(false);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      onSubmit();
    }
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <form>
            <div className="logo">
              <img src={logo} alt="Ariston" />
            </div>
            <Typography
              component="h1"
              variant="h5"
              align="center"
              className={classes.title}
            >
              ABILITAZIONE SERVIZIO CHAIN2
            </Typography>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              className={classes.stepper}
            >
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              {activeStep === steps.length ? (
                <React.Fragment>
                  {success == null ? (
                    <div className={classes.progress}>
                      <CircularProgress />
                    </div>
                  ) : success === false ? (
                    <Failure />
                  ) : (
                    <Success />
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {getStepContent(activeStep, request, setRequest)}
                  <div className={classes.buttons}>
                    {activeStep !== 0 && (
                      <Button
                        onClick={handleBack}
                        className={classes.buttonBack}
                      >
                        Indietro
                      </Button>
                    )}
                    <Button
                      disabled={
                        !validStepContent(activeStep, request, setRequest)
                      }
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.buttonPrincipal}
                    >
                      {activeStep === steps.length - 1
                        ? "Invia richiesta"
                        : "Continua"}
                    </Button>
                  </div>
                </React.Fragment>
              )}
            </React.Fragment>
          </form>
        </Paper>
        <Copyright />
      </main>
    </React.Fragment>
  );
}

export default AssociationForm;
