import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
    objectFit: "cover",
  },
  title: {
    marginBottom: 16,
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19.49px",
  },
});
export default function PodForm({ request, setRequest }) {
  const classes = useStyles();

  const [serialValid, setSerialValid] = useState(null);
  const [podValid, setPodValid] = useState(null);
  const [fiscalCodeValid, setFiscalCodeValid] = useState(null);

  // handle onchange
  const handleChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
  };

  const handleFiscalCodeChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
    setFiscalCodeValid(
      /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/.test(
        target.value
      )
    );
  };

  const handleSerialChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
    setSerialValid(/^\d{1}[abcdefABCDEF]\w{6}$/.test(target.value));
  };

  const handlePodChange = ({ target }) => {
    setRequest({
      type: "UPDATE_PERSONAL_INFO",
      payload: { [target.name]: target.value },
    });
    setPodValid(/^IT\d{3}E\d{8,10}$/i.test(target.value));
  };

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom className={classes.title}>
        DATI INTESTATARIO FORNITURA
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            variant="outlined"
            id="ownerFirstName"
            name="ownerFirstName"
            value={request.ownerFirstName || ""}
            label="Nome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            variant="outlined"
            id="ownerLastName"
            name="ownerLastName"
            value={request.ownerLastName || ""}
            label="Cognome"
            fullWidth
            onChange={(e) => handleChange(e)}
            autoComplete="family-name"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            required
            variant="outlined"
            id="fiscalCode"
            name="fiscalCode"
            error={!fiscalCodeValid && fiscalCodeValid !== null}
            value={request.fiscalCode || ""}
            label="Codice Fiscale"
            fullWidth
            onChange={(e) => handleFiscalCodeChange(e)}
            autoComplete="given-name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            error={!podValid && podValid !== null}
            type="pod"
            id="pod"
            value={request.pod || ""}
            name="pod"
            label="Codice POD"
            onChange={(e) => handlePodChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            variant="outlined"
            type="serial"
            error={!serialValid && serialValid !== null}
            id="serial"
            value={request.serial || ""}
            name="serial"
            label="Seriale"
            onChange={(e) => handleSerialChange(e)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="./assets/pod.png"
                title="Bolletta"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Individuabile nella sezione “dati fornitura” del frontespizio
                  della bolletta
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Card className={classes.root}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image="./assets/serial.png"
                title="Bolletta"
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  Riportato nell’etichetta su prodotto
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle2">
            L’attivazione del servizio avverrà entro 7 giorni lavorativi con
            apposita comunicazione all’indirizzo email indicato.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
