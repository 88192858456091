import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Amplify } from "aws-amplify";
import AssociationForm from "./association/association-form";

/* Amplify */
import awsExports from "./aws-exports";
import AdmissibilityForm from "./admissibility/admissibility-form";
Amplify.configure(awsExports);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

class App extends Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/association"
              name="Association Form"
              render={(props) => <AssociationForm {...props} />}
            />
            <Route
              exact
              path="/admissibility"
              name="Admissibility Form"
              render={(props) => <AdmissibilityForm {...props} />}
            />
            <Route
              path="/"
              name="Association Form"
              render={(props) => <AssociationForm {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
