import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PersonIcon from "@material-ui/icons/Person";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DoneIcon from "@material-ui/icons/Done";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import InputIcon from "@material-ui/icons/Input";
import LaunchIcon from "@material-ui/icons/Launch";
import MapIcon from "@material-ui/icons/Map";
import WcIcon from "@material-ui/icons/Wc";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";

const preventDefault = (event) => event.preventDefault();
const purposes = [
  {
    purpose:
      "Analisi e suddivisione in cluster delle tipologie di clienti per finalità di sviluppo del business",
    desc: "Interesse legittimo (miglior gestione aziendale)",
    period:
      "Durata contrattuale e, dopo la cessazione, per il periodo di prescrizione ordinario pari a 10 anni",
  },
  {
    purpose:
      "Adempiere ad obblighi previsti da regolamenti e dalla normativa nazionale e sovranazionale applicabile",
    desc: "Necessità di assolvere gli obblighi di legge",
    period:
      "Durata prevista dalla legge (10 anni per adempimenti amministrativo-contabili)",
  },
  {
    purpose:
      "Gestione della sicurezza e delle risorse informatiche (backup, restore, accessi logici all’eventuale account online)",
    desc: "Esecuzione di un contratto di cui Lei è parte",
    period:
      "Durata contrattuale e, dopo la cessazione, per il periodo di prescrizione ordinario pari a 10 anni",
  },
  {
    purpose:
      "Se necessario, per accertare, esercitare o difendere i diritti del Titolare in sede giudiziaria",
    desc: "Interesse legittimo (tutela giudiziaria)",
    period:
      "Nel caso di contenzioso giudiziale, per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione",
  },
  {
    purpose: "Recupero crediti stragiudiziale",
    desc: "Interesse legittimo (tutela giudiziaria)",
    period:
      "Nel caso di contenzioso giudiziale, per tutta la durata dello stesso, fino all’esaurimento dei termini di esperibilità delle azioni di impugnazione",
  },
  {
    purpose:
      "Finalità di marketing diretto: a titolo esemplificativo, invio - con modalità automatizzate di contatto (come sms, mms, e-mail, social networks, app di messaggistica istantanea) e tradizionali (come telefonate con operatore e posta tradizionale) - di comunicazioni promozionali e commerciali relative a servizi/prodotti offerti dalla Società o segnalazione di eventi aziendali, nonché rilevazione del grado di soddisfazione della clientela, realizzazione di indagini di mercato e analisi statistiche",
    desc: "Consenso (facoltativo e revocabile in qualsiasi momento)",
    period:
      "Dati anagrafici e dati di contatto: fino a revoca consenso. Dati inerenti il dettaglio degli acquisti: 24 mesi dalla raccolta di ciascun dato",
  },
  {
    purpose:
      "Finalità di profilazione: analisi delle Sue preferenze, abitudini, comportamenti, interessi (…) al fine di inviarle comunicazioni commerciali personalizzate/azioni promozionali mirate/offerte e servizi adeguate alle Sue esigenze/preferenze. (CRM metrics, Business Intelligence, estensione della garanzia…)",
    desc: "Consenso (facoltativo e revocabile in qualsiasi momento)",
    period:
      "Dati anagrafici e dati di contatto: fino a revoca consenso. Dati inerenti il dettaglio degli acquisti: 24 mesi dalla raccolta di ciascun dato",
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
}));

export default function Privacy() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container spacing={2} className={classes.header}>
        <Grid container xs={7} style={{ backgroundColor: "#d1dbf0" }}>
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Grid container>
              <Grid item xs={4} sm={4}>
                <PersonIcon style={{ fontSize: 80 }} />
              </Grid>
              <Grid item xs={8} sm={8}>
                <Typography variant="body2">
                  TITOLARE DEL TRATTAMENTO
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid container xs={5} sm={5} style={{ backgroundColor: "#d1dbf0" }}>
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">Ariston S.p.A.</Typography>
            <Typography variant="body2">Viale Aristide Merloni, 45</Typography>
            <Typography variant="body2">60044 Fabriano (AN) Italy</Typography>
            <Typography variant="body2">T. +39 0732 6011</Typography>
            <Typography variant="body2">Fax: +39 0732 602331</Typography>
            <Typography variant="body2">CF e P.IVA: IT01026940427</Typography>
            <Typography variant="body2">(“Società”)</Typography>
          </Box>
        </Grid>

        <Grid container xs={4} sm={4} style={{ backgroundColor: "#d1dbf0" }}>
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">FINALITÀ DEL TRATTAMENTO</Typography>
          </Box>
        </Grid>
        <Grid container xs={4} sm={4} style={{ backgroundColor: "#d1dbf0" }}>
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">
              BASE GIURIDICA DEL TRATTAMENTO
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={4} sm={4} style={{ backgroundColor: "#d1dbf0" }}>
          <Box border={1} borderColor="primary.main" padding={1} flex={1}>
            <Typography variant="body2">
              PERIODO DI CONSERVAZIONE DEI DATI
            </Typography>
          </Box>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Grid item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1}>
              <Typography variant="body2">
                Abilitazione del servizio Chain2 comprese tutte le attività
                connesse
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1}>
              <Typography variant="body2">
                Adesione a eventuali programmi di fidelizzazione e/o
                registrazione dati nel CRM della Società
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1}>
              <Typography variant="body2">
                Controllo da remoto dei prodotti della Società, prevenzione e
                risoluzione di anomalie di funzionamento, ulteriori servizi
                post-vendita
              </Typography>
            </Box>
          </Grid>
          <Grid style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1}>
              <Typography variant="body2">
                Report mensile dei consumi del prodotto (solo se il prodotto è
                connesso in Rete)
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container xs={4} sm={4}>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Esecuzione di un contratto di cui Lei è parte
            </Typography>
          </Box>
        </Grid>
        <Grid container xs={4} sm={4}>
          <Box border={1} borderColor="primary.main" padding={1}>
            <Typography variant="body2">
              Durata contrattuale e, dopo la cessazione, per il periodo di
              prescrizione ordinario pari a 10 anni
            </Typography>
          </Box>
        </Grid>

        {purposes.map((item, key) => (
          <React.Fragment key={key}>
            <Grid container xs={4} sm={4}>
              <Box
                border={1}
                borderColor="primary.main"
                padding={1}
                flex={1}
                style={{ backgroundColor: "#d1dbf0" }}
              >
                <Typography variant="body2">{item.purpose}</Typography>
              </Box>
            </Grid>
            <Grid container xs={4} sm={4}>
              <Grid container item>
                <Box border={1} borderColor="primary.main" padding={1} flex={1}>
                  <Typography variant="body2">{item.desc}</Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid container xs={4} sm={4}>
              <Box border={1} borderColor="primary.main" padding={1}>
                <Typography variant="body2">{item.period}</Typography>
              </Box>
            </Grid>
          </React.Fragment>
        ))}

        <Grid container xs={12} sm={12}>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Decorsi i termini di conservazione sopra indicati, i Dati
                saranno distrutti, cancellati o resi anonimi.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <InputIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                OBBLIGATORIETÀ DEL CONFERIMENTO DEI DATI
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Il conferimento dei dati evidenziati con un asterisco (*) nel
                form d’iscrizione è obbligatorio per la conclusione e
                prosecuzione del contratto; il rifiuto di fornire i suddetti
                dati non consente, pertanto, la conclusione del contratto e il
                poter usufruire dei servizi da Lei richiesti
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <LaunchIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">DESTINATARI DEI DATI</Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                I dati possono essere trattati sia da altre società del Gruppo
                Ariston sia da soggetti esterni operanti in qualità di titolari
                quali, a titolo esemplificativo, autorità ed organi di vigilanza
                e controllo ed in generale soggetti, pubblici o privati,
                legittimati a richiedere i dati. I dati potranno essere
                comunicati a notai e studi legali nonché a studi commercialisti.
                I dati possono altresì essere trattati, per conto della Società,
                da soggetti esterni designati come responsabili, a cui sono
                impartite adeguate istruzioni operative.
              </Typography>
              <Typography variant="body2">
                Tali soggetti sono essenzialmente ricompresi nelle seguenti
                categorie:
              </Typography>
              <ol type="a">
                <li>altre società del Gruppo Ariston;</li>
                <li>società che offrono servizi di invio e-mail;</li>
                <li>
                  società che offrono servizi di manutenzione del sito web;
                </li>
                <li>
                  società che offrono supporto nella realizzazione di studi di
                  mercato;
                </li>
                <li>società e agenzie di event management e trade fairs;</li>
                <li>
                  società che offrono servizi strumentali al perseguimento delle
                  finalità indicate nella presente informativa (media agency, IT
                  suppliers, spedizionieri, softwarehouse & system integrator,
                  società di e-commerce, società di consulenza…);
                </li>
                <li>
                  società che offrono servizi di call center e customer care;
                </li>
                <li>società di information provider;</li>
              </ol>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <WcIcon style={{ fontSize: 80 }} />
            <WcIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                SOGGETTI AUTORIZZATI AL TRATTAMENTO
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                I dati potranno essere trattati dai dipendenti delle funzioni
                aziendali deputate al perseguimento delle finalità sopra
                indicate, che sono stati espressamente autorizzati al
                trattamento e che hanno ricevuto adeguate istruzioni operative.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <MapIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                TRASFERIMENTO DEI DATI PERSONALI IN PAESI NON APPARTENENTI
                ALL’UNIONE EUROPEA
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                I dati potranno essere trasferiti all'estero in paesi
                extra-europei, ed in particolare in USA, paese il cui livello di
                protezione dei dati è stato ritenuto adeguato dalla Commissione
                Europea ai sensi dell’art. 45 del GDPR (Privacy Shield)
              </Typography>
              <Typography variant="body2">
                La decisione di adeguatezza della Commissione Europea può essere
                consultata al seguente link:{" "}
                <Link
                  href="http://eur-lex.europa.eu/legal-content/IT/TXT/PDF/?uri=CELEX:32016D1250&from=IT"
                  onClick={preventDefault}
                >
                  http://eur-lex.europa.eu/legal-content/IT/TXT/PDF/?uri=CELEX:32016D1250&from=IT
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            borderColor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <AccountBalanceIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                DIRITTI DELL'INTERESSATO - RECLAMO ALL’AUTORITÀ DI CONTROLLO
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Contattando Ariston S.p.A. a mezzo posta all’indirizzo v.le A.
                Merloni 45, Fabriano (AN), via e-mail all’indirizzo{" "}
                <Link
                  href="mailto:privacy.aristonthermo@ariston.com"
                  onClick={preventDefault}
                >
                  privacy.aristonthermo@ariston.com
                </Link>
                , gli interessati possono chiedere al titolare l’accesso ai dati
                che li riguardano, la loro cancellazione, la rettifica dei dati
                inesatti, l’integrazione dei dati incompleti, la limitazione del
                trattamento nei casi previsti dall’art. 18 GDPR, nonché
                l’opposizione al trattamento, per motivi connessi alla propria
                situazione particolare, nelle ipotesi di legittimo interesse del
                titolare.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati, inoltre, nel caso in cui il trattamento sia
                basato sul consenso o sul contratto e sia effettuato con
                strumenti automatizzati hanno il diritto di ricevere in un
                formato strutturato, di uso comune e leggibile da dispositivo
                automatico i dati, nonché, se tecnicamente fattibile, di
                trasmetterli ad altro titolare senza impedimenti.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati hanno il diritto di revocare il consenso
                prestato in qualsiasi momento per finalità di marketing e/o di
                profilazione, nonché di opporsi al trattamento dei dati per
                finalità di marketing, compresa la profilazione connessa al
                marketing diretto. Resta ferma la possibilità per l’interessato
                che preferisca essere contattato per la suddetta finalità
                esclusivamente tramite modalità tradizionali, di manifestare la
                sua opposizione solo alla ricezione di comunicazioni attraverso
                modalità automatizzate.
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Gli interessati hanno il diritto di proporre reclamo
                all'Autorità di controllo competente nello Stato membro in cui
                risiedono abitualmente o lavorano o dello Stato in cui si è
                verificata una presunta violazione.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        <Grid container xs={4} sm={4}>
          <Box
            border={1}
            bgcolor="primary.main"
            padding={1}
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ backgroundColor: "#d1dbf0" }}
          >
            <VisibilityIcon style={{ fontSize: 80 }} />
          </Box>
        </Grid>
        <Grid container xs={8} sm={8}>
          <Grid container item style={{ backgroundColor: "#d1dbf0" }}>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                PRESA VISIONE DEL’INFORMATIVA
              </Typography>
            </Box>
          </Grid>
          <Grid container item>
            <Box border={1} borderColor="primary.main" padding={1} flex={1}>
              <Typography variant="body2">
                Con la sottoscrizione della presente, dichiaro di aver ricevuto
                e preso visione dell’Informativa Privacy.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
